<template>
<div class="etf-backtest"> 
  <div align="start"> 
    <span class="text-h5 text--primary d-flex pa-2 font-weight-bold">글로벌 자산 백테스트      
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-icon class="pl-2" v-bind="attrs" v-on="on"> mdi-information </v-icon>
        </template>
        <BacktestHelp
          :he00 = "true"
        />
      </v-tooltip>
    </span>
  </div>

  
  <v-alert
      border="top"
      color="red lighten-2"
      dark
  >
    현재는 미국시장만 데이터 제공 중임 (데이터소스: 야후 파이넨스)
  </v-alert>
 

  <div class="d-flex justify-end pa-6">
    <v-btn 
      text
      color="primary"
      small 
      class="font-weight-bold" 
      @click="showBacktestHelp=true"
    > 
      <v-icon left> mdi-help-box </v-icon>
      나만의 전략을 만드는법 
    </v-btn>      
  </div>
  <!-- 나만의 전략을 만드는법 다이알로그-->
  <v-dialog
    v-model="showBacktestHelp"
    width="800"
  >
    <v-card>
      <v-card-title class="text-h6 grey lighten-2">
        나만의 전략을 만드는법
      </v-card-title>
      <v-card-subtitle class="d-flex justify-end text-caption grey lighten-2">
        [출처] 거인의 포트폴리오 (강한국작가)
      </v-card-subtitle>
      <v-card-text>
        <BacktestHelp
          :he02 = "true"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="showBacktestHelp = false"
        >
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-container fluid>
    <!--포트폴리오 선택 -->
    <v-radio-group
      v-model="selectedPortType"
      row  
      @change="resetPage"
    >
      <v-radio
        label="전략 직접 구성"
        value="my"
      ></v-radio>
      <v-radio
        label="전문가 전략 참조 구성"
        value="expert"
      ></v-radio>
    </v-radio-group>

    <!-- 정적/동적 선택 -->
    <v-radio-group
      v-if="isExpert"
      v-model="selectedType"
      row
      @change="resetPage"
    >
      <v-radio
        label="정적 자산배분"
        value="static"
      >      
      </v-radio>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-icon class="pr-7" v-bind="attrs" v-on="on"> mdi-information </v-icon>
        </template>
          일년에 한번 리밸런싱 원칙
      </v-tooltip>

      <v-radio
        label="동적(마켓타이밍) 자산배분"
        value="dynamic"
      ></v-radio>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information </v-icon>
        </template>
          과거 가격의 변동성, 경제지표를 고려하여 보통 한달에 한번 리밸런싱
      </v-tooltip>
    </v-radio-group>

    <!-- 전략 선택 -->
    <v-select
      v-if="isExpert"
      :items="titles"
      label="참조전략(모델)*"
      v-model="selectedTitles"      
      multiple
      chips
      clearable
      deletable-chips
      @change="get_assets()"
    ></v-select>

    <!-- 국가선택 -->
    <v-select
      v-if="!isExpert"
      :items="countries"
      label="국가"
      item-text="country"
      item-value="country"
      v-model="selectedCountry"
      @change="get_etfs()"
    ></v-select>

    <!-- 직접구성 etf 선택 -->
    <v-select
      v-if="!isExpert"
      v-model="selectedAsset"
      :items="myAssets"
      label="ETFs"
      @change="putMyPort()"
      hint="포트폴리오를 구성할 ETF를 선택하세요. (다중선택 가능)"
      persistent-hint
    >
      <template v-slot:prepend-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field 
              v-model="searchAsset" 
              placeholder="검색 (ETF명이나 티커를 입력하세요)" 
              @input="searchEtfs">
            </v-text-field>            
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>      
    </v-select>    
    <br>
    
    <!-- chip -->
    <v-container 
      v-if="!isExpert" 
      class="py-0"
    >
      <v-row
        align="center"
        justify="start"
      >
        <v-col
          v-for="(selection,i) in selectedAssets"
          :key="i"
          class="shrink"
        >
          <v-chip
            close
            @click:close="close(i)"
          >
            {{ selection }}
          </v-chip>
        </v-col>
      </v-row>
    </v-container>

    <!-- 직접구성 테이블 -->
    <br>
    <v-data-table
        v-if="showMyTable"
        :headers="mySingleHeaders"
        :items="assets"
        item-key="symbol"
        class="elevation-1"
        :hide-default-footer="true"
        dense
        :items-per-page="100"
    >
      <template v-slot:item.ratio="props">
        <v-edit-dialog
        :return-value.sync="props.item.ratio"
        large
        persistent
        @save="saveRatio()"
        cancel-text="취소"
        save-text="저장"        
        >
        <div>{{ props.item.ratio }}</div>
        <template v-slot:input>
            <div class="mt-4 text-subtitle">
            배분율
            </div>
            <v-text-field
            v-model="props.item.ratio"
            label="입력"
            single-line
            ></v-text-field>
        </template>
        </v-edit-dialog>
      </template>
    </v-data-table>


    <!-- 전문가전략 참고 구성 -->
    <template>
          <v-list 
            v-if="showList"
            flat
          >
            <v-list-item-group
            >
              <v-list-item
                v-for="(port, i) in multiPorts"
                :key="i"
              >
                <v-container align="end">          
                  <!-- 전략타이틀, 배분비율 -->
                  <v-row>
                    <v-col cols="8" sm="8" md="10" lg="10">
                      <span class="d-flex justify-start text-h6"> {{ port.title }} </span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2">
                      <v-text-field class="d-flex justify-end" v-model="port.stg_ratio" required label="배분비율(%)"></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- 전략설명문 -->
                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="port.description"
                        label="매수/매도 전략"
                        outlined
                        shaped
                        filled
                        auto-grow
                        background-color="amber lighten-4"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <!-- 전략별 옵션 추가 -->
                  <v-row>
                    <v-col cols="12" sm="12" md="4" lg="4">
                      <v-select
                        v-if="showMA[port.port_id]"
                        v-model="port.ma_period"
                        :items="periods"
                        label="기간"
                        hint="이동평균가격을 산정할 기간을 선택하세요. "
                        persistent-hint
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="4" lg="4">
                      <v-select
                        v-if="showMomentum[port.port_id]"
                        v-model="port.mt_period"
                        :items="periods"
                        label="기간"
                        hint="수익율을 산정할 기간을 선택하세요. "
                        persistent-hint
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                      v-if="showHoldToAseets[port.port_id]"
                        v-model="port.hold_to_assets"
                        label="보유할 자산수"
                      ></v-text-field>
                    </v-col>
                  </v-row>


                  <!-- 자산 테이블 -->
                  <v-row>
                    <v-col>
                      <v-data-table
                        :headers="expertHeaders"
                        :items="port.assets"
                        item-key="port.assets.symbol"
                        class="elevation-1"
                        :hide-default-footer="true"
                        :items-per-page="100"
                      >
                        <template v-slot:top>
                          <v-dialog
                            v-model="dialog[port.port_id]"
                            :key="port.port_id"
                            max-width="800px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn 
                                class="ma-5 d-flex justify-end font-weight-bold" 
                                text
                                color="primary"
                                small
                                v-on="on" 
                              > 
                                <v-icon left> mdi-plus </v-icon> 자산 추가
                              </v-btn>
                            </template>

                            <v-card>
                            <v-card-title>
                              <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="6" md="4">
                                    <!-- 국가선택 -->
                                    <v-select
                                      :items="countries"
                                      label="국가"
                                      item-text="country"
                                      item-value="country"
                                      v-model="selectedCountry"
                                      @change="get_etfs()"
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="8">
                                    <!-- etf 선택 -->
                                    <v-select
                                      v-model="selectedAsset"
                                      :items="myAssets"
                                      label="ETFs"
                                      hint="포트폴리오를 구성할 ETF를 선택하세요."
                                      persistent-hint
                                      @change="splitName"
                                    >
                                      <template v-slot:prepend-item>
                                        <v-list-item>
                                          <v-list-item-content>
                                            <v-text-field 
                                              v-model="searchAsset" 
                                              placeholder="검색 (ETF명이나 티커를 입력하세요)" 
                                              @input="searchEtfs">
                                            </v-text-field>            
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                      </template>      
                                    </v-select>    
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="editedItem.symbol"
                                      label="심볼"
                                      disabled
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="editedItem.etf"
                                      label="자산명"
                                      disabled
                                    ></v-text-field>
                                  </v-col>                                  
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field                                    
                                      v-if="!showExpertDynamic"
                                      v-model="editedItem.ratio"
                                      label="배분율(%)"
                                      type="number"
                                      v-on:change="editedItem.ratio = parseFloat(editedItem.ratio)"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      v-if="showCategory[port.port_id]"
                                      :items="categories"
                                      label="자산분류"
                                      v-model="editedItem.category"
                                    ></v-select>
                                  </v-col>                                  
                                </v-row>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="closeDialog(port.port_id)">
                                취소
                              </v-btn>
                              <v-btn color="blue darken-1" text @click="saveDialog(i, port.port_id)">
                                저장
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>                          
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon small class="mr-2" @click="editItem(i, port.port_id, item)"> mdi-pencil </v-icon>
                          <v-icon small @click="deleteItem(i, item)"> mdi-delete </v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-list-item>
            </v-list-item-group>  
          </v-list>
    </template>



    <!-- 초기투자금, 리벨런싱주기 -->
    <br>
    <v-container>                        
      <v-row>
      <v-col cols="6">
          <v-text-field          
            v-model="selectedAmount"
            label="초기투자금(USD)" required                            
          ></v-text-field>
      </v-col>
      <v-col cols="6">
          <v-select     
            v-model="selectedTerm"
            :items="tradeTerms"
            :disabled="showExpertDynamic"
            :menu-props="{ top: true, offsetY: true }"
            label="리밸런싱주기*" required
            hint="동적자산배분인 경우 매월 자동 리밸런싱"            
            persistent-hint
          ></v-select>                        
      </v-col>
      </v-row>                           
    </v-container>

    <!-- 백테스트 버튼 -->
    <v-spacer></v-spacer><v-spacer></v-spacer>
    <v-btn
      class="ma-6"
      color="primary"  
      @click="backtestStart"
    >
      백테스트
    </v-btn>

  </v-container>

  <!-- 알림 메세지 -->
  <v-snackbar
    v-model="snackbar"
    :vertical="vertical"
    :color="snackColor"          
  >
    {{ snackText }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>

</div>
</template>

<script>
  import axios from 'axios';
  import {mapState, mapActions, mapMutations} from 'vuex';
  import BacktestHelp from '../components/BacktestHelp.vue';

  export default {
    name: 'EtfBacktest',    
    components: { BacktestHelp },
    data: function () {
      return {
        isExpert: false,
        showExpertDynamic: false,
        showMyTable: false,
        showList: false,        
        showBacktestHelp: false,
        showMA: {port_id:Boolean},
        showMomentum: {port_id:Boolean},
        showHoldToAseets: {port_id:Boolean},
        showCategory: {port_id:Boolean},
        loadTable: false,
        dialog: {port_id:Boolean},

        titles: [],
        models: [],
        modelsCopy: [],        
        selectedPortType: 'my',
        selectedType: 'static',
        selectedCountry: 'united states',
        selectedTitles: [],
        selectedModels: [],
        selectedPortIds: [],
        selectedAsset: null,
        selectedAssets: [],
        selectedCategory: null,
        countries: [],
        assets: [], 
        myAssets: [], 
        searchAsset: '',
        assetsCopy: [],

        multiPorts: [],
        expertHeaders: [],
        expertStaticHeaders: [
          { text: '국가', value: 'country', align: 'start' },   
          { text: '심볼명', value: 'symbol', align: 'start' },          
          { text: 'ETF명', value: 'etf', align: 'start' },
          { text: '배분율', value: 'ratio', align: 'start' },
          { text: 'Actions', value: 'actions', align: 'center' },
        ],
        expertDynamicHeaders: [
          { text: '국가', value: 'country', align: 'start' },  
          { text: '심볼명', value: 'symbol', align: 'start' },          
          { text: 'ETF명', value: 'etf', align: 'start' },
          { text: '자산분류', value: 'category', align: 'start' },
          { text: 'Actions', value: 'actions', align: 'center' },
        ],
        mySingleHeaders: [
          { text: '국가', value: 'country', align: 'start' },  
          { text: '심볼명', value: 'symbol', align: 'start' },          
          { text: 'ETF명', value: 'etf', align: 'start' },
          { text: '배분율', value: 'ratio', align: 'start' },
        ],
        expandedHeaders: [
          { text: '국가', value: 'country', align: 'start' },  
          { text: '전략(모델)', value: 'title', align: 'start' },
          { text: '배분율', value: 'stg_ratio', align: 'end' },
        ],        
        expanded: [],
        sumRatio: 100,
        selectedAmount: 10000,
        selectedTerm: [],
        tradeTerms: ['월','분기','반기','년'],   
        description: null,

        editedIndex: -1,
        editedItem: {country: null, symbol: null, etf: null, ratio: null, category: null},
        defaultItem: {country: null, symbol: null, etf: null, ratio: null, category: null},
        
        snackbar: false,
        vertical: true,
        snackColor: null,
        snackText: null,

        // 동적자산배분 옵션 변수
        periods: [
          {text:'1개월', value:1},{text:'2개월', value:2},{text:'3개월', value:3},{text:'4개월', value:4},{text:'5개월', value:5},{text:'6개월', value:6},
          {text:'7개월', value:7},{text:'8개월', value:8},{text:'9개월', value:9},{text:'10개월', value:10},{text:'11개월', value:11},{text:'12개월', value:12},
          {text:'15개월', value:15},{text:'18개월', value:18},{text:'21개월', value:21},{text:'24개월', value:24},{text:'27개월', value:27},{text:'30개월', value:30},
          {text:'33개월', value:33},{text:'36개월', value:36},
        ],
        categories: [],
        categoryList: {
          26: ['상대모멘텀', '절대모멘텀'],
          27: ['상대모멘텀', '절대모멘텀'],
          28: ['공격형', '안전형'],
          30: ['공격형', '안전형', '카나리아'],
          31: ['고정', '타이밍1', '타이밍2'],
          36: ['파트1(주식)', '파트2(채권)', '파트3(부동산)', '파트4(불경기)'], 
        },
        
      }
    },
    
    computed: {
      ...mapState([ 'isLogin', 'debug', 'user', 'market', 'market_cap', 'sector', 'filters', 'rules' ]),
      formTitle () {
        return this.editedIndex === -1 ? '자산 추가' : '자산 수정'
      },
    },

    mounted() {
      if (!this.isLogin) this.$router.push('/');
      this.resetPage();
    },

    methods: {
      ...mapMutations(['setMarket', 'setEtfTest', 'setRoute']),

      resetPage() {
        if (this.selectedPortType == 'my') {
          this.isExpert = false;        
          this.get_countries();          
        } else if (this.selectedPortType == 'expert') {
          this.isExpert = true;
          this.showMyTable = false;
          this.showExpertDynamic = this.selectedType == 'dynamic' ? true : false;          
          this.expertHeaders = this.selectedType == 'dynamic' ? this.expertDynamicHeaders : this.expertStaticHeaders;
          this.selectedTerm = '월';
          this.get_models();
        }
        
        this.titles = [];
        this.multiPorts = [];
        this.selectedAsset = '';
        this.selectedAssets = [];     
        this.assets = [];     
          
      },

      get_models() {        
          var vm = this;
          this.selectedTitles = [];
          this.selectedModels = [];
          this.selectedPortIds = [];          

          const req_data = {'user': 'expert', 'type': this.selectedType}
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/etf/models/', req_data, {headers})
            .then(function(res) {
              vm.models = res.data;
              vm.models.forEach(element =>
                vm.titles.push(element['title'])
              );
            })
            .catch(function (err) {
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;            
            });
      },

      set_info() {
        this.selectedPortIds = [];
        this.selectedModels = this.models.filter(({ title
          }) => this.selectedTitles.includes(title));
        this.selectedModels.forEach(element => {
          this.selectedPortIds.push(element['port_id']);
        });
      },
      
      get_assets() {
        this.set_info();
        this.showList = this.selectedTitles.length > 0 ? true : false;      
        this.selectedPortIds.forEach(port_id => {
          this.$set(this.showMA, port_id, [10, 25, 27].includes(port_id));
          this.$set(this.showMomentum, port_id, [26, 36].includes(port_id));
          this.$set(this.showHoldToAseets, port_id, [27, 29, 30].includes(port_id));   
          this.$set(this.showCategory, port_id, [26, 27, 28, 30, 31, 36].includes(port_id));       
          this.categories = this.categoryList[port_id];
        })  
        
        this.multiPorts = [];
        this.selectedModels.forEach(model => {
          this.$set(this.dialog, model.port_id, false);
          this.multiPorts.push({port_id: model.port_id, title: model.title, description: model.description, 
            stg_ratio: Math.round(100/this.selectedModels.length),
            ma_period: model.ma_period, mt_period: model.mt_period, hold_to_assets: model.hold_to_assets, assets: model.assets});
        });
      },

      get_countries() {     
        // yahoo finance는 미국 자산만 보여주기때문에 국가리스트는 미국으로만 한정
        this.countries = ['united states'];
        this.get_etfs();     
        // var vm = this;
        // const req_data = {'user': vm.user}
        // const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
        // axios.post('/api/etf/countries/', req_data, {headers})
        //   .then(function(res) {
        //     vm.countries = res.data; 
        //     vm.get_etfs();  
        //   })
        //   .catch(function (err) {
        //     vm.snackbar = true;
        //     vm.snackColor = 'error';
        //     vm.snackText = err;            
        //   });
      },

      get_etfs() {    

        // 임시
        // this.myAssets = ['AAM S&P 500 Sector High Dividend ( SPDV )', 'Direxion NASDAQ-100 Equal Weighted Shares ( QQQE )'];
        // this.assetsCopy = this.myAssets;

        var vm = this;
        vm.loadTable = true;    
        const req_data = {'country': this.selectedCountry, 'format': 'short'}
        const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
        axios.post('/api/etf/etfs/', req_data, {headers})
          .then(function(res) {
            vm.myAssets = res.data;   
            vm.assetsCopy = vm.myAssets;
            vm.loadTable = false;
          })
          .catch(function (err) {
            vm.loadTable = false;    
            vm.snackbar = true;
            vm.snackColor = 'error';
            vm.snackText = err;
          });
      },
      
      searchEtfs(e) {
        if (!this.searchAsset) {
          this.myAssets = this.assetsCopy;
        }
        this.myAssets = this.assetsCopy.filter((asset) => {
          return asset.toLowerCase().indexOf(this.searchAsset.toLowerCase()) > -1;
        });
      },

      saveRatio() {
      this.sumRatio = this.assets.map(item => parseInt(item.ratio)).reduce((prev, curr) => prev + curr, 0);
        if (this.sumRatio !== 100) {
            this.snackbar = true
            this.snackColor = 'red lighten-1'
            this.snackText = '배분율 합계를 100%로 설정하세요.'
        }
      },

      putMyPort() {
        var vm = this;
        this.assets = [];
        this.multiPorts = [];
        this.selectedAssets.push(this.selectedAsset);
        this.showMyTable = true;
        
        this.selectedAssets.forEach(function(item){
          var etf = item.slice(0, item.indexOf(' ('));
          var symbol = item.slice(item.indexOf('(')+2, item.indexOf(')')-1);
          vm.assets.push({'country': vm.selectedCountry, 'etf':etf, 'symbol': symbol, 'ratio': Math.floor(100/vm.selectedAssets.length), category:'N/A'});
        });
        this.selectedTitles.push('포트폴리오 직접구성')
        this.multiPorts.push({port_id: 0, title: this.selectedTitles[0], description: null, stg_ratio: 100,
            ma_period: null, mt_period: null, hold_to_assets: null, assets: this.assets});
      },

      backtestStart() {
        // 전략별 배분율 유효성 검사
        var sumStgRatio = this.multiPorts.map(item => parseInt(item.stg_ratio)).reduce((prev, curr) => prev + curr, 0);
        
        if (sumStgRatio !== 100) {
            this.snackbar = true
            this.snackColor = 'red lighten-1'
            this.snackText = '전략별 배분율 합계를 100%로 설정하세요.'
        } else {
          var test_text = {};
          test_text['title'] = this.selectedTitles[0];
          test_text['who'] = this.selectedPortType;
          test_text['model_type'] = this.selectedType;
          test_text['ports'] = this.multiPorts;
          test_text['amount'] = this.selectedAmount;
          test_text['term'] = this.selectedTerm;
          this.setEtfTest(test_text);
          this.setRoute({'route': this.who});
          this.$router.push('/etf_backtest_result');
          }
      },

      deleteItem (index, item) {
        const editedIndex = this.multiPorts[index].assets.indexOf(item);
        this.multiPorts[index].assets.splice(editedIndex, 1);
      },

      editItem (index, port_id, item) {
        this.editedIndex = this.multiPorts[index].assets.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.$set(this.dialog, port_id, true);
      },

      close(item) {
        this.selectedAssets.splice(item, 1);
        this.assets.splice(item, 1);
        if (this.assets.length == 0) this.showMyTable = false;
      }, 

      closeDialog (port_id) {    
        this.$set(this.dialog, port_id, false); 
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1 
        });      
      },

      splitName () {
        var item = this.selectedAsset;
        this.editedItem.country = this.selectedCountry;
        this.editedItem.symbol = item.slice(item.indexOf('(')+2, item.indexOf(')')-1);
        this.editedItem.etf = item.slice(0, item.indexOf(' ('));
      },

      saveDialog (index, port_id) {     
        if (this.editedIndex > -1) {
          Object.assign(this.multiPorts[index].assets[this.editedIndex], this.editedItem);
        } else {
          var port = this.multiPorts.filter(p => p.port_id === port_id);
          port[0].assets.push(this.editedItem);
          this.multiPorts[index] = Object.assign({}, port[0]);
        }
         this.closeDialog (port_id);        
      },
     

    },
  }
</script>

<style>
.etf-backtest{
   padding: 12px;
   margin-top: 10px;
}
</style>